export const HOME = '/'

//Notification
export const NOTIFICATION = '/notification'

// Master Data
export const MASTER_DATA = '/master-data'
export const WAREHOUSE_LIST = '/master-data/warehouses'
export const SUPPLIER_LIST = '/master-data/suppliers'
export const DISTRIBUTOR_LIST = '/master-data/distributors'
export const DISTRIBUTOR_MARGIN = '/master-data/distributor-margin'
export const PRODUCT_LIST = '/master-data/products'
export const SUPPLIER_PRODUCT_LIST = '/master-data/supplier-products'
export const PRODUCT_PRE_ORDER_INDIA = '/master-data/product-pre-orders'

//Product
export const MENU_PRODUCT = '/product'
export const PRODUCT_AVAILABLE = '/product/product-available'
export const PRODUCT_LIST_VN = '/product/products'
export const PRODUCT_LISTING = '/product/product-listings'
export const SUPPLIER_PRODUCT_LIST_VN = '/product/supplier-products'
export const PRODUCT_TRADING_BY_LISTING = '/product/product-trading-by-listings'
export const PRODUCT_PRE_ORDER = '/product/product-pre-orders'

// Partner
export const PARTNER = '/partner'
export const SUPPLIER_LIST_VN = '/partner/suppliers'

// Distributor profile
export const DISTRIBUTOR_MANAGEMENT = '/distributor-management'
export const DISTRIBUTOR_PROFILE = '/distributor-management/distributor-profile'
export const DISTRIBUTOR_LIST_VN =
  '/distributor-management/distributor-profile/distributors'
export const DISTRIBUTOR_REGISTER =
  '/distributor-management/distributor-register'

// Supplier Portal
export const SUPPLIER_PORTAL = '/supplier-portal'
export const RETAIL_PURCHASE_REQUEST_LIST =
  '/supplier-portal/retail-purchase-requests'
export const PURCHASE_ORDER_LIST = '/supplier-portal/purchase-orders'
export const BIDDING_REQUEST = '/supplier-portal/bidding-requests'
export const BIDDING_INVITATION_LIST = '/supplier-portal/bidding-invitations'

// Brand Portal
export const BRAND_PORTAL = '/brand-portal'
export const SALES_PERFORMANCE = '/brand-portal/sales-performance'
export const CATEGORY_INSIGHTS = '/brand-portal/category-insights'
export const DELIVERY_PERFORMANCE = '/brand-portal/delivery-performance'
export const INVENTORY_REPORT = '/brand-portal/inventory-report'
export const AGENTS_PERFORMANCE = '/brand-portal/agents-performance'
export const OUTLETS_PERFORMANCE = '/brand-portal/outlets-performance'
export const COVERAGE_REPORT = '/brand-portal/coverage-report'
export const PRICE_MANAGEMENT = '/brand-portal/price-management'
export const MISSION_PERFORMANCE = '/brand-portal/mission-performance'

// WMS
export const WMS = '/wms'
export const WAREHOUSE_WMS_LIST = '/wms/warehouses'
export const INBOUND_LIST = '/wms/inbounds'
export const INVENTORY_LIST = '/wms/inventories'
export const INVENTORY_ADJUSTMENT = '/wms/inventories-adjustment'
export const STOCK_TRANSFER = '/wms/stock-transfer'

export const AUTO_INVENTORY_REPLENISHMENT = '/wms/auto-inventory-replenishment'

// Settings
export const SETTINGS = '/settings'
export const USER_LIST = '/settings/users'
export const TASK_LIST = '/settings/tasks'
export const PROCUREMENT_CONFIG = '/settings/procurement'

// Fulfillment
export const FULFILLMENTS = '/fulfillment'
export const ORDER_LIST = '/fulfillment/orders'
export const PRE_ORDERS = '/fulfillment/pre-orders'
export const TRADE_ORDERS = '/fulfillment/trade-orders'
export const PICKLIST_ASSIGNMENT = '/fulfillment/picklist/assignment'
export const PICKLIST_CHECKOUT = '/fulfillment/picklist/checkout'

export const FINANCE = '/finance'
export const BUY_IN_INVOICE = '/finance/buy-in-invoice'
export const SELL_OUT_INVOICE = '/finance/sell-out-invoice'
export const PAYOUT_STATEMENT = '/finance/payout-statement'
export const FINANCE_TASK_LIST = '/finance/tasks'
export const PAYMENT_REQUEST = '/finance/payment-request'
export const PRICE_TYPE_CONFIG = '/finance/price-type-config'
export const CLAIMS_MANAGEMENT = '/finance/claims-management'
export const CLAIMS = '/finance/claims-management/claims'
export const CLAIMS_INVOICE = '/finance/claims-management/claim-invoice'
export const REASON_NEED_ADJUST = '/finance/reason-need-adjust'
export const TAX_MANAGEMENT = '/finance/tax-management'
export const SUPPLIER_INVOICE_PRODUCT_MANAGEMENT =
  '/finance/supplier-invoice-product'
export const DISTRIBUTOR_CONTRACT_FEE = '/finance/distributor-contract-fee'

export const RIDER = '/rider-app'
export const RIDER_MANAGEMENT = '/rider-app/riders'
export const DELIVERY_MANAGEMENT = '/rider-app/deliveries'
export const DELIVERY_MANAGEMENT_HUB = '/rider-app/deliveries-hub'
export const FAILED_DELIVERY_RECEIVE = '/rider-app/failed-delivery-receive'
export const PACKAGE_LIST = '/rider-app/packages'
export const VEHICLE_MANAGEMENT = '/rider-app/vehicles'
export const REMITTANCE_LIST = '/rider-app/remittances'
// Report
export const REPORT = '/report'
export const REPORT_RIDER = '/report/rider'

export const REPORT_GST_PURCHASES = '/report/gst-purchases/'
export const REPORT_GST_PURCHASE_REGISTER =
  '/report/gst-purchases/gst-purchase-register'
export const REPORT_PURCHASE_REGISTER_DETAILED =
  '/report/gst-purchases/purchase-register-detailed'
export const REPORT_PURCHASE_RETURN = '/report/gst-purchases/purchase-return'

export const REPORT_GST_SALES = '/report/gst-sales/'
export const REPORT_GSTR_SALES_REGISTER =
  '/report/gst-sales/gstr-sales-register'
export const REPORT_GST_SALES_RETURN = '/report/gst-sales/gst-sales-return'

export const REPORT_GST_SALES_ADMIN = '/report/gst-sales-admin/'
export const REPORT_GST_SALES_REGISTER_ADMIN =
  '/report/gst-sales-admin/gst-sales-register'
export const REPORT_GST_SALES_RETURN_ADMIN =
  '/report/gst-sales-admin/gst-sales-return'

export const REPORT_GST_SALES_HUB = '/report/gst-sales-hub/'
export const REPORT_GST_SALES_REGISTER_HUB =
  '/report/gst-sales-hub/gst-sales-register-hub'
export const REPORT_GST_SALES_REGISTER_DETAILED_HUB =
  '/report/gst-sales-hub/gst-sales-register-detailed-hub'
export const REPORT_GST_SALES_RETURN_HUB =
  '/report/gst-sales-hub/gst-sales-return-hub'

export const REPORT_SUMMARY = '/report/summary'
export const REPORT_GST_SUMMARY_BY_HSN = '/report/summary/gst-summary-by-hsn'
export const REPORT_INVOICE_LOADING = '/report/summary/invoice-loading'
export const REPORT_PRODUCT_SUMMARY = '/report/summary/product-summary'

export const REPORT_INVENTORY = '/report/inventory'
export const REPORT_INVENTORY_SNAPSHOT = '/report/inventory/inventory-snapshot'
export const REPORT_PURCHASE_RECONCILIATION =
  '/report/inventory/purchase-reconciliation'

export const REPORT_CLAIMS = '/report/claims'
export const REPORT_CLAIM = '/report/claims/claim'
export const REPORT_CLAIMS_RETURN = '/report/claims/claims-return'

export const REPORT_SALES_SUMMARY = '/report/sales-summary'
export const REPORT_SALES_SUMMARY_SKU = '/report/sales-summary/sku'
export const REPORT_SALES_SUMMARY_OUTLET = '/report/sales-summary/outlet'

export const REPORT_BATCH = '/report/batch'
export const REPORT_BATCH_SALES = '/report/batch/sales'
export const REPORT_BATCH_SALES_RETURN = '/report/batch/sales-return'
export const REPORT_BATCH_PURCHASE = '/report/batch/purchase'
export const REPORT_BATCH_PURCHASE_RETURN = '/report/batch/purchase-return'

// Report VN
export const REPORT_DISTRIBUTOR_RECONCILIATION =
  '/report/distributor-reconciliation'
export const REPORT_DISTRIBUTOR_RECONCILIATION_SUMMARY =
  '/report/distributor-reconciliation-summary'

// Marketing
export const MARKETING = '/marketing'
export const MARKETING_BUDGET = '/marketing/budget'
export const SUBSIDY_RULE = '/marketing/subsidy-rule'
export const AGENT_INCENTIVES = '/marketing/agent-incentives'
export const INCENTIVES_CONFIG = '/marketing/agent-incentives/config'
export const AGENT_GROUP = '/marketing/agent-incentives/agent-group'

// Distributor Center
export const DISTRIBUTOR_CENTER = '/distributor-center'
export const PURCHASING = '/distributor-center/purchasing'
export const CREDIT = '/distributor-center/credit'

// Procurement
export const PROCUREMENT = '/procurement'
export const DISTRIBUTOR_PURCHASE_REQUEST =
  '/procurement/distributor-purchase-request'
export const CONSOLIDATED_PURCHASE_REQUEST =
  '/procurement/consolidated-purchase-request'
export const VIGO_MASTER_QUOTATION = '/procurement/vigo-master-quotation'
export const DISTRIBUTOR_PURCHASE_ORDER =
  '/procurement/distributor-purchase-order'
export const VIGO_PURCHASE_ORDER = '/procurement/vigo-purchase-order'
export const INVENTORY_RECOMMEND = '/procurement/inventory-recommend'
export const REASON_CANCEL = '/procurement/reason-cancel'
export const AUTOMATIC_PROCUREMENT_CONFIG =
  '/procurement/automatic-procurement-config'
export const AUTOMATIC_PARTIAL_FULFILLMENT =
  '/procurement/automatic-partial-fulfillment'
export const GOODS_NOT_FOCUS = '/procurement/goods-not-focus'
export const CONFIG_CM3_TARGET = '/procurement/config-cm3-target'

//Logistics
export const LOGISTICS = '/logistics'
export const TRANSPORTATION_VENDOR = '/logistics/transportation-vendor'
export const VIGO_DELIVERY = '/logistics/vigo-delivery'

// Return
export const RETURN = '/return'
export const RETURN_ORDER_LIST = '/return/orders'
export const CREDIT_DEBIT_NOTE = '/return/credit-debit-note'

//Outlet
export const OUTLET_MANAGEMENT = '/outlet-management'
